<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="900px">
    <el-form ref="form" :model="form" size="small">
      <div class="colum">
        <el-form-item label="平台类型" prop="platformType" style="width: 300px;">
          <el-radio v-model="form.platformType" label="1">MANAGER</el-radio>
          <el-radio v-model="form.platformType" label="2">CLIENT</el-radio>
        </el-form-item>
        <el-form-item label="允许开户" prop="isAllowToOpen" style="width: 300px;" >
          <el-radio v-model="form.isAllowToOpen" label="1">是</el-radio>
          <el-radio v-model="form.isAllowToOpen" label="2">否</el-radio>
        </el-form-item>
      </div>
      <div class="colum">
        <el-form-item label="所属站点">
          <el-select v-model="form.siteId" placeholder="所属站点" >
            <el-option
              v-for="item in siteList"
              :key="item.id"
              :label="item.siteName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="平台名称" style="width: 400px;">
          <el-input v-model="form.platformName"/>
        </el-form-item>
        <el-form-item label="合作状态" style="width: 150px;">
        <el-select v-model="form.status" placeholder="合作状态" >
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
      </div>
      <div v-if='form.isAllowToOpen==1' class="colum">
        <el-form-item label="平台官网">
          <el-input type="textarea" autosize v-model="form.platformUrl" style="width: 400px;"/>
        </el-form-item>
        <el-form-item  label="交易平台" style="width: 400px;">
          <el-input v-model="form.tradePlatform"/>
        </el-form-item>
      </div>
      <div v-if='form.isAllowToOpen==1' >
        <el-form-item label="平台中文简介">
          <el-input type="textarea" autosize v-model="form.profile" style="width: 800px;" />
        </el-form-item>
        <el-form-item label="平台英文简介">
          <el-input
            type="textarea"
            autosize
            v-model="form.profileEn"
            style="width: 800px;"
          />
        </el-form-item>
      </div>
      <div v-if='form.isAllowToOpen==1'  class="colum">
        <el-form-item label="交易信息">
          <el-input type="textarea" autosize v-model="form.tradeInfo" style="width: 800px;"/>
        </el-form-item>
      </div>
      <div v-if='form.isAllowToOpen==1' class="colum">
        <el-form-item label="监管组织">
          <el-input v-model="form.regulatedBy"/>
        </el-form-item>
        <el-form-item label="支持币种">
          <el-input v-model="form.currency" />
        </el-form-item>
        <el-form-item label="最少入金">
          <el-input v-model="form.least" />
        </el-form-item>
        <el-form-item label="最大杠杆">
          <el-input v-model="form.maxLev" />
        </el-form-item>
      </div>
      <div v-if='form.isAllowToOpen==1' class="colum">
        <el-form-item  label="代理号">
          <el-input v-model="form.ibNo"/>
        </el-form-item>
        <el-form-item  label="代理名称">
          <el-input v-model="form.ibName" />
        </el-form-item>
         <el-form-item type="textarea" autosize label="注册链接" style="width: 400px;">
          <el-input v-model="form.regUrl" />
        </el-form-item>
      </div>
      <div v-if='form.isAllowToOpen==1' class="colum">
        <el-form-item type="textarea" autosize label="注册参数" style="width: 400px;">
          <el-input v-model="form.regLink" />
        </el-form-item>
        <el-form-item label="代理注册参数">
          <el-input type="textarea" autosize v-model="form.subIbRegLink" style="width: 400px;"/>
        </el-form-item>
      </div>
      <div v-if='form.isAllowToOpen==1' class="colum">

        <el-form-item label="回调链接">
          <el-input type="textarea" autosize v-model="form.resultUrl" style="width: 400px;"/>
        </el-form-item>
      </div>
      <div class="colum">
        <el-form-item label="排序号" >
          <el-input  v-model="form.sortNo" style="width: 50px;"/>
        </el-form-item>
         <el-form-item label="备注" >
          <el-input type="textarea" autosize v-model="form.remark" style="width: 700px;"/>
        </el-form-item>
      </div>
      <el-form-item label="图标">
           <el-row>
            <el-col :span="4">
              <el-upload
                ref="upload"
                class="upload"
                :action="uploadUrl"
                :on-success="handleSuccess"
                :limit=1
                multiple
                method:="post"
                :show-file-list="false"
                :file-list="fileLists">
                <el-button size="small" type="primary" plain>选择文件</el-button>
              </el-upload>
            </el-col>
            <el-col :span="4">
              <img v-if="form.icon!=null" :src="form.icon" alt="" style="width:50px;height:50px;">
            </el-col>
          </el-row>
      </el-form-item>
      <el-form-item label="图标链接">
        <el-input v-model="form.icon" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/community/srPlatformInfo'
import { initData } from '@/api/data'
export default {
  props: {
    sup_this: {
      type: Object,
      default: null
    },
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false, dialog: false,siteList:[],fileLists:[],
      statusList:[
        {id:'1',name:'正常'},
        {id:'2',name:'终止合作'},
        {id:'9',name:'即将上线'}
      ],
      uploadUrl:process.env.VUE_APP_API+'community/auth/file/upload',
      form: {
        id: '',
        siteId:1,
        platformId: '',
        platformName: '',
        platformType:'1',
        platformUrl: '',
        tradePlatform:'',
        regulatedBy:'',
        currency:'',
        least:'',
        maxLev:'',
        tradeInfo:'',
        profile:'',
        profileEn:'',
        regLink: '',
        subIbRegLink:'',
        regUrl:'',
        resultUrl:'',
        icon:'',
        ibNo: '',
        ibName: '',
        status: '1',
        remark: '',
        isAllowToOpen:'1',
        sortNo:99,
        isDefault:2
      }
    }
  },
  created() {
    this.initSiteList()
  },
  methods: {
    handleSuccess(response){
        //上传成功要处理的事
        this.form.icon=response.data.content[0].url;
    },
    initSiteList(){
      const url = 'cms/crm/srSite'
      const sort = 'sortNo,asc'
      const params = {sort: sort,page:0,size:1000}
      initData(url, params).then(res => {
          this.siteList = res.content
          this.form.siteId= this.siteList[0].id
        }).catch(err => {
          reject(err)
        })
    },
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.fileLists=[]
      this.form = {
        id: '',
        siteId:1,
        platformId: '',
        platformName: '',
        platformType:'1',
        platformUrl: '',
        tradePlatform:'',
        regulatedBy:'',
        currency:'',
        least:'',
        maxLev:'',
        tradeInfo:'',
        profile:'',
        regLink: '',
        subIbRegLink:'',
        regUrl:'',
        resultUrl:'',
        icon:'',
        ibNo: '',
        ibName: '',
        status: '1',
        remark: '',
        isAllowToOpen:'1',
        sortNo:99,
        isDefault:2
      }
    }
  }
}
</script>

<style scoped>
.colum{
  display: flex;
  justify-content: row center;
}
.el-form-item{
  margin-left: 10px;
}
</style>
