<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form" :sup_this="sup_this" :is-add="false"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        siteId:this.data.siteId,
        platformType: this.data.platformType.toString(),
        platformId: this.data.platformId,
        platformName: this.data.platformName,
        platformUrl: this.data.platformUrl,
        tradePlatform:this.data.tradePlatform,
        regulatedBy:this.data.regulatedBy,
        currency:this.data.currency,
        least:this.data.least,
        maxLev:this.data.maxLev,
        tradeInfo:this.data.tradeInfo,
        profile:this.data.profile,
        profileEn:this.data.profileEn,
        regLink: this.data.regLink,
        subIbRegLink: this.data.subIbRegLink,
        regUrl:this.data.regUrl,
        resultUrl:this.data.resultUrl,
        icon:this.data.icon,
        ibNo: this.data.ibNo,
        ibName: this.data.ibName,
        status: this.data.status+'',
        remark: this.data.remark,
        isAllowToOpen: this.data.isAllowToOpen.toString(),
        sortNo:this.data.sortNo,
        isDefault:this.data.isDefault
      }
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
